// This page is used to strip out the user's auth token from `document.cookie` before
// the value is funneled into Stripe's API
import { navigate } from 'gatsby';
import * as React from 'react';

const Sanitize: React.FC = () => {
    React.useEffect(() => {
        const siteAccessTokenPair = document.cookie
            .split('; ')
            .find(row => row.startsWith('DAMLHUB_SITE_TOKEN'));

        const siteAccessToken = siteAccessTokenPair?.slice(siteAccessTokenPair.indexOf('=') + 1);

        if (siteAccessToken && siteAccessToken !== 'undefined') {
            localStorage.setItem(
                'dabl_tokens',
                JSON.stringify({ version: 1, tokens: { '.admin/.site': siteAccessToken } })
            );
            console.log('User token set...');
        } else {
            console.warn('User token not found in cookies');
        }

        navigate('/console');
        console.log('Directing user to the console...');
    }, []);

    return (
        <div className="gatsby-sanitize container">
            <p>Directing you to the console...</p>
        </div>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default Sanitize;
